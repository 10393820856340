.empty {
    width: 100%;
    height: 10px;
}

.result {
    width: 100%;
    height: 200px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 0.1vw solid #ebedf0;
    border-bottom: 0.1vw solid #ebedf0;
}

.result_icon {
    width: 80px;
}

.result_text {
    margin-top: 15px;
    font-size: 18px;
}

.button {
    margin-top: 20px;
    padding: 0px 15px;
}
