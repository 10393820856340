.empty {
    width: 100%;
    height: 10px;
}

.title {
    width: 350px;
    margin: 0 auto;
    font-weight: bold;
}

.banner {
    width: 350px;
    height: 230px;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 10px;
}

.banner_image {
    width: 350px;
    height: 230px;
    background-position: center;
    background-size: cover;
}

.card {
    width: 350px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
}

.item {
    width: 350px;
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.image {
    width: 80px;
    height: 80px;
    margin-right: 12px;
    background-size: cover;
    background-position: center;
    background-color: #82d2d2;
}

.text {
    width: 225px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon {
    display: flex;
    align-self: center;
}

.title {
    font-size: 15px;
    font-weight: bold;
}

.remark {
    margin-top: 12px;
}
