.empty {
    width: 100%;
    height: 10px;
}

.checkbox {
    padding-left: 11px;
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: -15px;
}

.checkbox_text {
    font-size: 14px;
}

.agreement {
    color: #1989fa;
}

.agreement_content {
    color: #1989fa;
    background-color: #ffffff;
    height: 400px;
    padding: 15px;
    overflow-y: scroll;
    color: #000000;
    font-size: 14px;
}

.button {
    margin-top: 30px;
    padding: 0px 15px;
}

.disAmt{
    font-weight: bold;
    color: red;
}
.txnAmt{
    font-weight: bold;
}
