.title {
    width: 345px;
    margin: 0 auto;
    margin-top: 20px;
    font-weight: bold;
}

.subTitle {
    width: 345px;
    margin: 0 auto;
    margin-top: 5px;
}

.banner {
    width: 375px;
    height: 250px;
    border-radius: 4px 4px 0px 0px;
    margin: 0 auto;
}

.banner_image {
    width: 375px;
    height: 250px;
    background-position: center;
    background-size: cover;
}

.list {
    width: 345px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.goods {
    width: 100%;
    padding-bottom: 15px;
    background-color: #ffffff;
}

.goods_title {
    margin-top: 10px;
    padding: 15px 15px 0px 15px;
    font-size: 16px;
    font-weight: bold;
}

.goods_price {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px 15px 5px 10px;
}

.goods_market_price {
    margin-top: 5px;
    padding-left: 5px;
    text-decoration: line-through;
}

.goods_sale_price {
    margin-top: 5px;
    padding-left: 5px;
    color: red;
    font-weight: bold;
    font-size: 20px;
}

.goods_text {
    margin-top: 5px;
    padding: 0px 15px 0px 15px;
}

.shop_row {
    margin-top: 5px;
    display: flex;
}

.shop_title {
    margin-bottom: 5px;
    font-weight: bold;
}

.shop_value {
    margin-left: 0px;
    width: 86%;
}

.card {
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    margin: 0 auto;
    margin-top: 15px;
    background-color: #ffffff;
}

.card_title {
    font-size: 16px;
    font-weight: bold;
    padding: 15px 15px 0px 15px;
}

.card_content {
    margin-top: 10px;
    padding: 0px 15px 15px 15px;
}

.empty {
    width: 100%;
    height: 10px;
}

.footer {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 0.1vw solid #ebedf0;
}

.footer_button {
    margin-right: 10px;
}
