.title {
    width: 345px;
    margin: 0 auto;
    margin-top: 20px;
    font-weight: bold;
}

.subTitle {
    width: 345px;
    margin: 0 auto;
    margin-top: 5px;
}

.list {
    width: 345px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.shop {
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
}

.shop_item {
}

.goods {
    width: 165px;
    height: 250px;
    margin-top: 20px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #ffffff;
}

.goods_image {
    width: 165px;
    height: 165px;
    background-size: cover;
    background-position: center;
}

.goods_title {
    margin-top: 10px;
    padding-left: 5px;
    font-weight: bold;
}

.goods_market_price {
    margin-top: 5px;
    padding-left: 5px;
    text-decoration: line-through;
}

.goods_sale_price {
    margin-top: 5px;
    padding-left: 5px;
    color: red;
    font-weight: bold;
}

.empty {
    width: 100%;
    height: 10px;
}
