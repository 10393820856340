.empty {
    width: 100%;
    height: 10px;
}

.captcha {
    width: 110px;
}

.button {
    margin: 30px 15px 20px 15px;
}

.action {
    width: 345px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.action_item {
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #666666;
}
