.bottom {
    color: rgb(22, 119, 255);
}

.row {
    width: 371px;
    min-height: 46px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.row_month {
    width: 371px;
    min-height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
}

.col {
    width: 53px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.month {
    margin-left: 10px;
    font-weight: bold;
}

.day {
    width: 36px;
    height: 36px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.day_disable {
    color: #c8c9cc;
}

.day_active {
    background-color: rgb(22, 119, 255);
    border-radius: 4px;
    color: #ffffff;
}

.day_close {
    position: absolute;
    bottom: 3px;
    font-size: 10px;
    color: #c8c9cc;
}

.empty {
    width: 100%;
    height: 15px;
}
