.content{
    padding: 0 15px;
    height: 95vh;
    overflow-y: scroll;
}
.pageTitle{
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
}

.exchange{
    display: flex;
    justify-content: space-between;
}
.exchange .input{
    width: 70vw;
    padding: 0 15px;
    background: #fff;
}
.exchange .exbtn{
    width: 20vw;
}

.ksycode{
    margin-top: 20px;
}
.title{
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}
.title span{
    font-size: 12px;
}
.list{
}
.couponBlock{
    background: #FBECC3;
    border-radius: 10px;
    margin: 12px 0;
    padding: 10px 15px;
}
.r1{
    display: flex;
    justify-content: space-between;
}
.r1 .r1Left{
    width: 70%;
}
.r1 .r1Left h3{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
}
.r1 .r1Left p{
    color: #666666;
    padding: 5px 0;
    font-size: 12px;
}
.r1 .r1Right{
    width:30%;
    color: #FA6401;
    text-align: center;
}
.r1 .r1Right span{}
.r1 .r1Right span em{
    font-size: 20px;
    font-weight: bold;
}
.r1 .r1Right p{
    font-size: 12px;
}
.r1Right2{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.r2{
    border-top: dashed  1px #dddddd;
    line-height: 30px;
    font-size: 12px;
    color: #999999;
}

.bkycode{
    margin-top: 20px;
}
.confirmPadding{
    width: 100%;
    height: 100px;
}
.confirm{
    position: fixed;
    width: 92vw;
    bottom: 15px;
    border-radius: 10px;
    height: 44px;
    background: #fff;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.confirmLeft{
    padding-left: 15px;
}
.confirmRight{
    width: 30vw;
    text-align: center;
    background-color: #1677ff;
    height: 44px;
    line-height: 44px;
    color: #ffffff;
    font-size: 16px;
}
