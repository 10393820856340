.page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.shop {
    margin: 0 auto;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
}

.shop_image {
    width: 46px;
    height: 46px;
    border-radius: 6px;
    margin-right: 10px;
    object-fit: cover;
}

.shop_row {
    margin-top: 5px;
    display: flex;
}

.shop_title {
    margin-bottom: 5px;
    font-weight: bold;
}

.shop_value {
    margin-left: 5px;
}

.empty {
    width: 100%;
    height: 10px;
}
