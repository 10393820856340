.title {
    width: 345px;
    margin: 0 auto;
    margin-top: 20px;
    font-weight: bold;
}

.subTitle {
    width: 345px;
    margin: 0 auto;
    margin-top: 5px;
}

.shop {
    padding: 0 5px;
}

.shop_image {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    margin-right: 5px;
    object-fit: cover;
    display: block;
}

.shop_row {
    margin-top: 5px;
    display: flex;
}

.shop_title {
    margin-bottom: 5px;
    font-weight: bold;
}

.shop_value {
    margin-left: 0px;
}

.list {
    width: 350px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.item {
    width: 170px;
}

.goods {
    width: 170px;
    margin-top: 10px;
    border-radius: 3px;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 6px;
}

.goods_row {
    display: flex;
    margin: 10px;
}

.goods_shop {
    margin-top: 3px;
}

.goods_image {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center;
    margin: 10px;
    background-color: #82d2d2;
    object-fit: cover;
    display: block;
}

.goods_title {
    margin-top: 5px;
    padding-left: 10px;
    font-weight: bold;
}

.goods_market_price {
    margin-top: 5px;
    padding-left: 10px;
    text-decoration: line-through;
    font-weight: bold;
}

.goods_sale_price {
    margin-top: 5px;
    margin-bottom: 12px;
    padding-left: 10px;
    color: red;
    font-weight: bold;
}

.empty {
    width: 100%;
    height: 10px;
}
