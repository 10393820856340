.footer {
    width: 100%;
    height: 50px;
    border-top: 1px solid #eee;
}

.footer_item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer_item_image {
    width: 22px;
}

.footer_item_text {
    color: #1989fa;
}
