.empty {
    width: 100%;
    height: 10px;
    background-color: #82d2d2;
}

.empty_2 {
    width: 100%;
    height: 10px;
}

.card {
    width: 326px;
    margin: 0 auto;
    margin-top: 10px;
    padding: 15px;
}

.number {
    font-size: 24px;
    display: flex;
    font-weight: bold;
    margin-top: 10px;
}

.space {
    width: 15px;
}

.profile {
    margin: 20px 0;
    display: flex;
}

.profile_image {
    width: 55px;
    height: 55px;
}

.profile_title {
    font-size: 22px;
    margin-top: 1px;
}

.profile_level {
    color: #ffffff;
    font-size: 12px;
    padding: 2px 5px;
    background-color: #1989fa;
    border-radius: 2px;
}

.login {
    margin: 20px 0;
    display: flex;
}

.login_image {
    width: 55px;
    height: 55px;
    margin-right: 15px;
}

.login_title {
    font-size: 22px;
    margin-top: 5px;
    color: #000000;
}

.login_tips {
    margin-top: 5px;
    color: #969799;
}
