.empty {
    width: 100%;
    height: 10px;
}

.title {
    width: 345px;
    margin: 0 auto;
    font-weight: bold;
}

.banner {
    width: 345px;
    height: 230px;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 10px;
}

.banner_image {
    width: 345px;
    height: 230px;
    background-position: center;
    background-size: cover;
}

.notice {
    width: 345px;
    height: 100px;
    margin: 0 auto;
    margin-top: 10px;
    overflow-y: scroll;
}

.notice_item {
    display: flex;
    align-items: center;
    height: 25px;
}

.notice_item_icon {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: #1989fa;
    margin-right: 10px;
}

.notice_item_title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.category {
    width: 345px;
    height: 100px;
    margin: 0 auto;
    margin-top: 10px;
}

.category_item {
    width: 107px;
    height: 107px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.category_item_image {
    width: 50px;
    height: 50px;
}
