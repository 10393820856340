.empty {
    width: 100%;
    height: 10px;
}

.button {
    padding: 15px;
}

.logout {
    width: 100%;
    text-align: center;
    color: red;
}
