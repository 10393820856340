.empty {
    width: 100%;
    height: 10px;
}

.button {
    margin: 130px 15px 20px 15px;
}

.wechat {
    position: fixed;
    left: 50%;
    margin-left: -20px;
    bottom: 60px;
    width: 40px;
    height: 40px;
}

.action {
    width: 345px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.action_item {
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #666666;
}
