.page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.empty {
    width: 100%;
    height: 10px;
}

.row {
    width: 100%;
    display: flex;
    align-items: center;
}

.col {
    flex: 1;
}

.col_button {
    margin-left: 15px;
}

.tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

.goods {
    display: flex;
}

.goods_image {
    width: 80px;
    height: 80px;
    margin-right: 15px;
    background-color: #dcdcdc;
    background-size: cover;
}

.goods_main {
    flex: 1;
}

.goods_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.goods_text {
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.goods_price {
    color: red;
}

.trade_status {
    text-align: right;
    color: #1989fa;
}

.trade_no{
    color: #f53117;

}

.profile {
    margin: 20px 0;
    display: flex;
}

.profile_image {
    width: 55px;
    height: 55px;
}

.profile_title {
    font-size: 22px;
    margin-top: 1px;
}

.profile_level {
    color: #ffffff;
    font-size: 12px;
    padding: 2px 5px;
    background-color: #1989fa;
    border-radius: 2px;
}

.login {
    margin: 20px 0;
    display: flex;
}

.login_image {
    width: 55px;
    height: 55px;
    margin-right: 15px;
}

.login_title {
    font-size: 22px;
    margin-top: 5px;
    color: #000000;
}

.login_tips {
    margin-top: 5px;
    color: #969799;
}
