html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    width: 100%;
    height: 100%;
}

div {
    font-size: 14px;
}

.ka-wrapper {
    width: 100%;
    height: 100%;
}

.ka-content {
    width: 100%;
    height: 100%;
}

div,
form,
img,
ul,
ol,
li,
dl,
dt,
dd,
p,
tr,
td,
body,
strong,
span,
pre,
input {
    margin: 0;
    padding: 0;
    border: 0;
    background-repeat: no-repeat;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

img {
    border: 0px;
    max-width: 100%;
}

/* ul,
li {
    list-style: none;
} */

em {
    font-style: normal;
}

a:link {
    color: black;
}

a:visited {
    color: black;
}

.header:after {
    width: 200%;
    height: 200%;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    border-radius: 0;
}

.header:after {
    content: "";
    pointer-events: none;
    position: absolute;
    width: 200%;
    height: 200%;
    left: 0;
    top: 0;
    border-radius: 0;
    border-bottom: 1px solid #dcdcdc;
}

.adm-tab-bar-item {
    color: #000000;
}

.adm-tab-bar-item-active {
    color: #1989fa !important;
}

.adm-tabs-tab {
    padding-top: 15px;
}

.adm-tabs {
    height: auto !important;
}

.adm-toast-mask .adm-toast-wrap {
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    text-align: left;
    /* background-color: transparent; */
    transform: none;
}

.adm-swiper {
    overflow: visible;
}

.adm-error-block-description {
    margin-top: 0px !important;
}

p,
li,
td,
th,
blockquote {
    white-space: pre-wrap; /* 显示空格 */
}

/* 表格 */
table {
    border-collapse: collapse;
}
table th,
table td {
    border: 1px solid #ccc;
    min-width: 50px;
    height: 20px;
    text-align: left;
}
table th {
    background-color: #f1f1f1;
    text-align: center;
}

/* 代码块 */
pre > code {
    display: block;
    border: 1px solid hsl(0, 0%, 91%);
    border-radius: 4px 4px;
    text-indent: 0;
    background-color: #fafafa;
    padding: 10px;
    font-size: 14px;
}

/* 引用 */
blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 10px 10px;
    margin: 10px 0;
    background-color: #f1f1f1;
}

/* 列表 */
ul,
ol {
    margin: 10px 0 10px 20px;
}

/* 分割线 */
hr {
    display: block;
    width: 90%;
    margin: 20px auto;
    border: 0;
    height: 1px;
    background-color: #ccc;
}

img {
    max-width: 100%;
}
